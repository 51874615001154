import React, { useContext, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  AccordionBody,
  Container,
  AccordionHeader,
  UncontrolledAccordion,
  AccordionItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import NumericInput from "react-numeric-input";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import VCFundService from "../service/VCFundService";
import { UserContext } from "../UserContext";

import BaseService from "../service/BaseService.js";
import VCFundCard from "../components/VCFundCard.js";
import PostInvestmentService from "../service/PostInvestmentService.js";
import DataTable from "react-data-table-component";
import { ArrowDown } from "iconsax-react";
import { HambergerMenu } from "iconsax-react";
import { Form, Field } from 'react-final-form';

export const FundStatsComponent = (props) => {
  let { fundName } = useParams();
  const [fund, setFund] = useState({ meta: {} });
  const [stats, setStats] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState(false);
  const [selectedLP, setSelectedLP] = useState("");
  const [commitmentAmount, setCommitmentAmount] = useState("");
  const [lpEntities, setLpEntities] = useState([]);

  const [context] = useContext(UserContext);
  let navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const close = () => {
    navigate(-1);
  };

  const loadData = async () => {
    setLoading(true);
    setStats(undefined);
    let token = await getAccessTokenSilently();
    try {
      let d = await VCFundService.getFundByName(token, fundName);

      if (d) {
        setFund(d);
      } else {
        setFund({ meta: {} });
      }
      let stats = await VCFundService.getFundStats(token, fundName);
      setStats(stats);
      await fetchLpEntities(token);
    } catch (e) {
      setFund({ meta: {} });
    }

    setLoading(false);
  };

  const fetchLpEntities = async (token) => {
    try {
      const response = await VCFundService.getLpEntitiesWithoutCommitment(token, fundName);
      setLpEntities(response.data);
    } catch (error) {
      console.error("Error fetching LP entities:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, [fundName]);

  const startPostInvestment = async () => {
    let token = await getAccessTokenSilently();

    await PostInvestmentService.createPostInvestment(token, fund.id);
    loadData();
  };

  const hasPostInvestment = () => {
    return stats?.postInvestment;
  };

  const sumOfCommitAmount = (data) => {
    return BaseService.formatMoney(
      data
        .map((d) => d.meta.amount)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0)
    );
  };

  const extendDDAction = async (data) => {
    try {
      confirmAlert({
        title: "Extend Data room countdown",
        message:
          "How many days from today would you like to extend the DD period?",
        childrenElement: () => (
          <input type="number" id="ddExtensionDays" min="1" defaultValue="1" />
        ),
        buttons: [
          {
            label: "Extend",
            onClick: async () => {
              const days = document.getElementById("ddExtensionDays").value;
              if (days && parseInt(days) > 0) {
                let token = await getAccessTokenSilently();
                await VCFundService.extendDD(token, data.id, parseInt(days));
                loadData();
              } else {
                alert("Please enter a valid number of days.");
              }
            },
          },
          {
            label: "Cancel",
            onClick: () => {},
          },
        ],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const deleteDDAction = async (data) => {
    try {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              let token = await getAccessTokenSilently();
              VCFundService.deleteDDFundAction(token, data.id)
                .then(() => {
                  loadData();
                })
                .catch((e) => {
                  console.log(e);
                });
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const deleteAction = async (data) => {
    try {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              let token = await getAccessTokenSilently();
              VCFundService.deleteVCFundAction(token, data.id)
                .then(() => {
                  loadData();
                })
                .catch((e) => {
                  console.log(e);
                });
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const viewPostInvestment = async (data) => {
    navigate(
      `/admin/fundStats/${fund.name}/editPostInvestment/${data.lpEntityCode}`
    );
  };

  const [dropdownOpen, setDropdownOpen] = useState({});

  const toggleDropdown = (rowId) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const columns = [
    {
      name: "LP Entity",
      selector: (row) => row.lpEntity?.companyName,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) =>
        row.meta?.lastVisited
          ? BaseService.getDateString(row.meta.lastVisited)
          : BaseService.getDateString(row.createdAt),
      sortable: true,
    },
    {
      grow: 4,
      name: "Details",
      allowOverflow: false,
      selector: (row) =>
        `${
          row.meta.amount
            ? "Amount: " + BaseService.formatMoney(row.meta.amount)
            : ""
        } ${row.meta?.comments || ""}
        
        ${row.dataroomCountdownMsg?.msg || ""}

        ${row.meta?.receiveReminders ? " [Receive Reminders Checked]" : ""}

        ${
          row.meta?.byUser?.given_name
            ? "By: " +
              row.meta.byUser.firstName +
              " " +
              row.meta.byUser.lastName
            : ""
        }`,
      sortable: true,
    },
    {
      name: "",
      compact: true,
      cell: (row) => {
        if (row.action === "Commit" && stats.postInvestment) {
          return (
            <Button
              className="primary-button base-button mx-auto my-1"
              onClick={() => {
                viewPostInvestment(row);
              }}
            >
              Post Investment
            </Button>
          );
        } else if (
          (row.action === "Commit" && !stats.postInvestment) ||
          row.action ||
          row.status === "Requested"
        ) {
          return (
            <Dropdown
              isOpen={dropdownOpen[row.id]}
              toggle={() => toggleDropdown(row.id)}
            >
              <DropdownToggle tag="div">
                <HambergerMenu size="24" color="#000000" />
              </DropdownToggle>
              <DropdownMenu>
                {row.action && (
                  <DropdownItem
                    onClick={() => deleteAction(row)}
                    disabled={hasPostInvestment()}
                  >
                    Delete
                  </DropdownItem>
                )}
                {row.status === "Requested" && (
                  <>
                    <DropdownItem
                      onClick={() => deleteDDAction(row)}
                      disabled={hasPostInvestment()}
                    >
                      Relock DD
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => extendDDAction(row)}
                      disabled={hasPostInvestment()}
                    >
                      Extend DD
                    </DropdownItem>
                  </>
                )}
              </DropdownMenu>
            </Dropdown>
          );
        }
      },
    },
  ];

  const getColumns = (type) => {
    if (type === "Visited") {
      let newColumns = [...columns];
      const lastVistedColumn = {
        name: "Last Visited Date",
        selector: (row) => BaseService.getDateString(row.meta.lastVisited),
        sortable: true,
      };
      newColumns[1] = lastVistedColumn;
      return newColumns;
    } else {
      return [...columns];
    }
  };

  const toggleModal = async () => {
    if (!modal) { // Check if the modal is being opened
      const token = await getAccessTokenSilently(); // Get the access token
      const fundId = fund.id; // Assuming you have the fund ID available
      const entities = await VCFundService.getLpEntitiesWithoutCommitment(token, fundId); // Fetch LP Entities
      setLpEntities(entities); // Update the state with fetched entities
    }
    setModal(!modal);
  };

  const handleCommitment = async (values) => {
    
    setModal(!modal);
    // Add comment to values
    values.comments = "added through admin access"; 

    confirmAlert({
      title: "Confirm Commitment",
      message: `Are you sure you want to commit ${values.amount} to ${values.selectedLP}?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const token = await getAccessTokenSilently();
            await VCFundService.applyFundActionByName(
              token,
              fundName,
              values.selectedLP,
              "Commit",
              values
            );
            loadData(); // Reload data after commitment
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Container className="mb-5">
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`/admin`}>VC Funds</Link>
        </BreadcrumbItem>
        {fundName !== "undefined" && (
          <BreadcrumbItem active>{fundName}</BreadcrumbItem>
        )}
      </Breadcrumb>

      {loading && <Loading />}
      {!loading && (
        <>
          <VCFundCard data={fund}></VCFundCard>
          <div>
          <Button
            className="primary-button base-button my-3 mr-2"
            onClick={loadData}
          >
            Reload Data
          </Button>
          <Button onClick={toggleModal} className="primary-button base-button my-3">
            Add Commitment
          </Button>
          </div>
        </>
      )}

      {stats && (
        <UncontrolledAccordion
          className="my-5"
          defaultOpen={Object.keys(stats?.vcFundActions).concat("Report")}
          stayOpen
        >
          <AccordionItem key={"Report"}>
            <AccordionHeader targetId={"Report"}>
              Unlocked DD Report
            </AccordionHeader>
            <AccordionBody accordionId={"Report"}>
              <DataTable
                columns={getColumns("Report")}
                data={stats.reports}
                sortIcon={<ArrowDown className="mx-3" />}
              />
            </AccordionBody>
          </AccordionItem>

          {Object.keys(stats.vcFundActions).map((type) => {
            return (
              <AccordionItem key={type}>
                <AccordionHeader targetId={type}>{type}</AccordionHeader>
                <AccordionBody accordionId={type}>
                  <DataTable
                    key={"table-" + type}
                    columns={getColumns(type)}
                    data={stats.vcFundActions[type]}
                    sortIcon={<ArrowDown className="mx-3" />}
                  />

                  {type === "Commit" && !stats.postInvestment && (
                    <Button
                      className="primary-button base-button my-5 mr-5"
                      onClick={() => {
                        startPostInvestment();
                      }}
                    >
                      Start Post Investment
                    </Button>
                  )}

                  {type === "Commit" && (
                    <Button className="primary-button base-button my-5 mr-5">
                      Commit Amount:{" "}
                      {sumOfCommitAmount(stats.vcFundActions[type])}
                    </Button>
                  )}
                </AccordionBody>
              </AccordionItem>
            );
          })}
        </UncontrolledAccordion>
      )}

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Commitment</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={async (values) => {
              // Handle the commitment submission
              await handleCommitment(values);
            }}
            render={({ handleSubmit,
              values,
             }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <label>Select LP:</label>
                  <Field name="selectedLP">
                    {({ input }) => (
                      <select {...input}>
                        <option value="">Select an LP</option>
                        {lpEntities?.map((lp) => (
                          <option key={lp.code} value={lp.code}>
                            {lp.code} - {lp.companyName}
                          </option>
                        ))}
                      </select>
                    )}
                  </Field>
                </div>
                <div>      
                  Commitment Amount:{" "}
                  {values?.amount &&
                  BaseService.formatMoney(values?.amount)}
                </div>
                <div>
                  <Field name="amount">
                    {({ input }) => (
                      <NumericInput
                        type="number"
                        {...input}
                        placeholder="Enter amount"
                      />
                    )}
                  </Field>
                </div>
                <div>
                  <Field name="skipNotification" type="checkbox">
                    {({ input }) => (
                      <div>
                        <label>
                          <input type="checkbox" {...input} />
                          Skip sending notification to LPs
                        </label>
                      </div>
                    )}
                  </Field>
                </div>
                <ModalFooter>
                  <Button color="primary" type="submit">
                    Commit
                  </Button>
                  <Button color="secondary" onClick={toggleModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            )}
          />
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default withAuthenticationRequired(FundStatsComponent, {
  onRedirecting: () => <Loading />,
});
