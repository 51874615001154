import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";
import { getConfig } from "../config";
import moment from "moment";

import {
  isInitialized as isFullStoryInitialized,
  identify as fullstoryIdentify,
} from "@fullstory/browser";

class BaseService {
  static config = getConfig();

  static baseURL() {
    let current =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? this.config.localApiServer
      : current;
  }

  static baseVersion() {
    return "api/v1/";
  }

  static logUser(userId, userType) {
    Sentry.setContext("user", {
      id: userId,
      type: userType,
    });

    ReactGA.set({ userId: userId });
    ReactGA.set({ uid: userId });

    ReactGA.gtag("set", "user_properties", {
      userId: userId,
    });

    if (isFullStoryInitialized()) {
      fullstoryIdentify(userId, {
        userType: userType,
      });
    }
  }

  static async login(token) {
    let baseURL = this.baseURL();
    const response = await fetch(`${baseURL}api/v1/login`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });

    const responseData = await response.json();

    if (responseData?.data?.id) {
      this.logUser(responseData?.data?.id, responseData?.data?.type);
    }
    return responseData;
  }

  static async updateUserMeta(token, meta) {
    let baseURL = this.baseURL();
    const response = await fetch(`${baseURL}api/v1/updateUserMeta`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ meta: meta }),
      credentials: "include",
    });

    const responseData = await response.json();

    return responseData;
  }

  static async getUser(token) {
    let baseURL = this.baseURL();
    let maxRetries = 5;
    let retryCount = 0;
    let retryDelay = 1000; // Start with 1 second delay

    while (retryCount < maxRetries) {
      try {
        const response = await fetch(`${baseURL}api/v1/getUser`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        });

        const responseData = await response.json();
        if (responseData && responseData.error) {
          throw new Error("User not found");
        } else {
          this.logUser(responseData?.data?.id, responseData?.data?.type);
          return responseData;
        }
      } catch (E) {
        console.log(`Attempt ${retryCount + 1} failed:`, E);

        if (retryCount < maxRetries - 1) {
          const currentDelay = retryDelay;
          await new Promise((resolve) => setTimeout(resolve, currentDelay));
          retryDelay *= 2;
          retryCount++;
        } else {
          console.error("Max retries reached, redirecting to signup");
          this.signout();
        }
      }
    }
  }

  static signout() {
    const signUpRoute = "signup";
    window.location = "/#/" + signUpRoute + "?failed=1&app=base";
  }

  static async activateInvitation(token, data) {
    let baseURL = this.baseURL();
    const response = await fetch(`${baseURL}api/v1/activate-invitation`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(data),
      credentials: "include",
    });

    const responseData = await response.json();
    return responseData;
  }

  static async getLPProfile(token, params = {}) {
    let baseURL = this.baseURL();
    const response = await fetch(
      `${baseURL}api/v1/getLPProfile?profileOnly=${params?.profileOnly}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );

    const responseData = await response.json();
    return responseData;
  }

  static async getCurrentLPProfile(token, params = {}) {
    let baseURL = this.baseURL();
    const response = await fetch(`${baseURL}api/v1/getCurrentLPProfile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });

    const responseData = await response.json();
    return responseData;
  }
  static async getLPTeamMembers(token, lpEntityCode) {
    let baseURL = this.baseURL();
    const response = await fetch(`${baseURL}api/v1/getLPTeamMembers`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ lpEntityCode: lpEntityCode }),
      credentials: "include",
    });

    const responseData = await response.json();

    return responseData;
  }

  static async techCrunchNews() {
    try {
      let url = `https://techcrunch.com/wp-json/tc/v1/magazine?page=1&_embed=true&cachePrevention=0`;
      const response = await fetch(url, {});
      const responseData = await response.json();

      responseData.map((d) => {
        d.source = "Tech Crunch";
        d.title = d.title.rendered;
        d.imgURL = d.jetpack_featured_media_url;
        d.link = d.canonical_url;
      });

      return responseData;
    } catch (error) {
      console.error(error);
    }
  }

  static async newsAPINews(token, topic) {
    let baseURL = this.baseURL();
    const response = await fetch(`${baseURL}api/v1/news/${topic}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });

    const responseData = await response.json();

    responseData.articles?.map((d) => {
      d.source = d.source.name;
      d.title = d.title;
      d.imgURL = d.urlToImage;
      d.link = d.url;
      d.date = d.publishedAt;
    });
    return responseData;
  }

  static async ping(token, restricted = false) {
    try {
      let baseURL = this.baseURL();

      let url = `${baseURL}api/v1/restricted-ping`;
      if (!restricted) {
        url = `${baseURL}api/v1/ping`;
      }
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error(error);
    }
  }

  static async getDataroomEmbedURL(token, digifyRoomGuid) {
    try {
      let baseURL = this.baseURL();

      let url = `${baseURL}api/v1/digify-content/${digifyRoomGuid}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error(error);
    }
  }

  static async digify(token) {
    try {
      let baseURL = this.baseURL();

      let url = `${baseURL}api/v1/digify`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error(error);
    }
  }
  static async getNotion(token, pageId) {
    try {
      let baseURL = this.baseURL();

      let url = `${baseURL}api/v1/notion-page/${pageId}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error(error);
    }
  }

  static getDateString(date) {
    if (!date) {
      return "";
    }
    return moment(date).format("MMM DD, YYYY");
  }
  static getLocalDateTimeString(date) {
    if (!date) {
      return "";
    }
    return moment(date).format("MMM DD, YYYY hh:mm A");
  }

  static formatMoney = (value, notation = "compact") => {
    let formatter = Intl.NumberFormat("en", {
      style: "currency",
      currency: "USD",
      notation: notation,
    });

    if (!value) {
      return "";
    }
    return formatter.format(value);
  };

  static convertArrayOfObjectsToCSV = (array) => {
    if (!array || array.length === 0) {
      return;
    }
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        if (
          item[key] &&
          (typeof item[key] === "object" || Array.isArray(item[key]))
        ) {
          result += '"' + JSON.stringify(item[key]).replace(/"/g, "'") + '"';
        } else {
          result += '"' + item[key] + '"';
        }

        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  };
  static downloadCSV = (array) => {
    const link = document.createElement("a");
    let csv = this.convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };
}

export default BaseService;
