import BaseService from "./BaseService";

class VCFundService {
  static async getAll(
    token,
    limit = 20,
    offset = 0,
    name = "",
    publicView = true,
    viewStatus = undefined,
    tier = undefined
  ) {
    try {
      let baseURL = BaseService.baseURL();
      const response = await fetch(
        `${baseURL}api/v1/funds?limit=${limit}&offset=${offset}&name=${name}&publicView=${publicView}&viewStatus=${viewStatus}&tier=${tier}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const responseData = await response.json();
      return responseData;
    } catch (e) {
      return [];
    }
  }
  static async search(token, params = {}) {
    let {
      name = "",
      limit = 10,
      offset = 0,
      publicView = true,
      tiers = [],
    } = { ...params };
    try {
      let baseURL = BaseService.baseURL();
      const response = await fetch(
        `${baseURL}api/v1/funds?limit=${limit}&offset=${offset}&name=${name}&publicView=${publicView}&tiers=${tiers}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const responseData = await response.json();
      return responseData;
    } catch (e) {
      return [];
    }
  }

  static async getFeaturedFunds(token) {
    let baseURL = BaseService.baseURL();
    try {
      const response = await fetch(`${baseURL}api/v1/funds?featured=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });

      const responseData = await response.json();

      return responseData;
    } catch (e) {
      return [];
    }
  }

  static async getFundStats(token, name) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/getFundStats/${name}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async getFundByName(token, name) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/getFund/${name}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async favFundByName(token, name) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/getFund/${name}/favorite`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "post",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async unfavFundByName(token, name) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/getFund/${name}/favorite`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "delete",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async applyFundActionByName(token, name, lpEntityCode, action, meta) {
    let d = JSON.stringify({ lpEntityCode: lpEntityCode, meta: meta });
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/getFund/${name}/${action}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: d,
      method: "post",
    });
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    } else {
      const responseData = await response.json();
      return responseData;
    }
  }

  static async applyDDFundByName(token, name, lpEntityCode, receiveReminders) {
    let d = JSON.stringify({
      lpEntityCode: lpEntityCode,
      receiveReminders: receiveReminders,
    });

    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/getFund/${name}/applyDD`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: d,
      method: "post",
    });
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    } else {
      const responseData = await response.json();
      return responseData;
    }
  }

  static async unapplyDDFundByName(token, name) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/getFund/${name}/applyDD`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "delete",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async addFund(token, data) {
    let d = JSON.stringify(data);
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/funds`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: d,
      credentials: "include",
    });
    return response.statusText;
  }

  static async editFund(token, id, data) {
    let d = JSON.stringify({ ...data, id: id });
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/funds/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: d,
      credentials: "include",
    });
    return response.statusText;
  }

  static async deleteFund(token, id) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/funds/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      credentials: "include",
    });
    return response.statusText;
  }

  static async deleteVCFundAction(token, id) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/vcFundAction/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      credentials: "include",
    });
    return response.statusText;
  }

  static async deleteDDFundAction(token, id) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/ddFundAction/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      credentials: "include",
    });
    return response.statusText;
  }

  static async extendDD(token, id, days = 3) {
    let baseURL = BaseService.baseURL();

    let d = JSON.stringify({ days: days });

    const response = await fetch(`${baseURL}api/v1/extend-dd/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "post",
      credentials: "include",
      body: d,
    });
    return response.statusText;
  }

  static async getLpEntitiesWithoutCommitment(token, fundId) {
    let baseURL = BaseService.baseURL();
    try {
      const response = await fetch(`${baseURL}api/v1/lpentities/withoutCommitment?fundId=${fundId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });

      const responseData = await response.json();

      return responseData;
    } catch (e) {
      return [];
    }
  }
}

export default VCFundService;
